import React, { useContext, useEffect, useState } from 'react'
import { MyContext } from '../../App';
import { getClass_list, get_service_schedule_calendar, get_workshop_schedule_calendar, myclassList } from '../../Services/Services';
import moment from 'moment';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Loginmodals from '../../Mymodals/Loginmodals';
import { CurrentTime } from './CurrentTime';
import { Link, useLocation } from 'react-router-dom';
import { InputField } from '../../SheredComponent/InputField';
import { changeTimeStampToUTCDate, checkCurrentDateFilter, newUserRedirect } from '../../HelperFunction/Helper';
import { errorAlert } from '../../HelperFunction/Alertmsg';


export default function Workshoplist({ webpreview, maindata, setLoder }) {
    const { bzdata, fullInfo } = useContext(MyContext);
    const [myclsdata, setMyclsdata] = useState();
    const [isopen, setIsopen] = useState(false);
    const [sectionTitle, setSectionTitle] = useState('Workshop');
    const [mytoken, setMytoken] = useState();
    const getcls = async (bzdata) => {
        const formattedDate = moment().format('YYYY-MM-DD');
        const unixTimestamp = moment(formattedDate, 'YYYY-MM-DD').unix();
        const setdate = unixTimestamp;
        const fdata = {
            'business_id': bzdata.business_id,
            'scheduled_date': setdate,
        }
        const resp = await myclassList(fdata);
        if (resp && resp.status === 1) {
            setMyclsdata(resp.data);
        }
    }

    useEffect(() => {
        if (bzdata) {
            getcls(bzdata);
            localStorage.setItem('w_detailBack', '/my-home');
            localStorage.setItem("bzid", bzdata.business_id);
        };

    }, [bzdata]);

    useEffect(() => {
        if (maindata && maindata.business_token) {
            const ttt = maindata.business_token;
            setMytoken(maindata.business_token);
            if (ttt) { setParam1(ttt); }
      
        }
    }, [maindata])


    const handleShow = () => {
        setIsopen(true);

    }
    const isClose = () => {
        setIsopen(false);

    }



    const [param1, setParam1] = useState();
    const [param2, setParam2] = useState(2);

    const [todayclassList, setTodayclassList] = useState([]);
    const [currentDateSelect, setCurrentDateSelect] = useState('');
    const [messageContent, setMessageContent] = useState("No events scheduled for this chosen time frame")
    const location = useLocation();
    const [monthYearCombo, setMonthYearCombo] = useState(moment().format('YYYY-MM'));

    useEffect(() => {
        const token = mytoken;   
        if (token) { setParam1(token); }
     
    }, [location.search]);

    useEffect(() => {
        if (param2 && param1) {
            getInfo();
        }
    }, [param2, param1, monthYearCombo])

    const getInfo = async () => {
        let dateFrom = `${monthYearCombo}-01`;
        let currentMonth = moment().format('MM');
        let currentYear = moment().format('YYYY');
        let splitDate = dateFrom.split('-');



        let dateTo = moment(`${dateFrom}`, "YYYY-MM-DD").subtract(0, "months").add(6, 'months').format("YYYY-MM-DD");
        let resp = '';
        let tempObj = {};
        let type = '';
        if (param2 == 1) {
            type = 1;
        } else if (param2 == 2) {
            type = 2;
        } else {
            type = 3;
        }

        if (currentMonth == splitDate[1] && currentYear == splitDate[0]) {
            dateFrom = moment().format("YYYY-MM-DD");
        }
        tempObj.business_token = param1;
        tempObj.start = dateFrom;
        tempObj.end = dateTo;
        setLoder(true);
        resp = await get_workshop_schedule_calendar(tempObj);
        if (resp) {
            setLoder(false);
            let respData = resp.data.data;
            respData = respData.filter((e)=>e.visibility=='yes');
            for (let i = 0; i < respData.length; i++) {
                let rowObject = respData[i];
                const currentDate = moment().format("DD/MM/YYYY");
                const now = moment().format("HH:mm:ss");

                // if (param2 == "2") {
                rowObject.from_time = rowObject.start;
                rowObject.to_time = rowObject.end;
                rowObject.location = rowObject.location_name;
                rowObject.class_name = rowObject.name;
                rowObject.scheduled_date = moment.unix(rowObject.start).format("YYYY-MM-DD");
             
                const then = moment.unix(rowObject.from_time).format("YYYY-MM-DD HH:mm:ss");
                var endTime = moment(then, "YYYY-MM-DD HH:mm a");
                const currentTime = moment();
                let remainingCounter = "00:00:00";
                let remainingCounter11;
                const cur = moment().format("Y-MM-DD");
                const cDate = new Date(cur);
                const scheduleCreate = new Date(rowObject.scheduled_date);
                if (scheduleCreate >= cDate) {
                    if (endTime.isAfter(currentTime)) {
                        const startTime = currentDate + " " + now;
                        const endTime = moment.unix(rowObject.from_time).format("DD/MM/YYYY HH:mm:ss");
                        let ms = moment(endTime, "DD/MM/YYYY HH:mm:ss").diff(
                            moment(startTime, "DD/MM/YYYY HH:mm:ss")
                        );
                        let d = moment.duration(ms);
                        let days = Math.floor(d.asDays()) < 10 ? "0" + Math.floor(d.asDays()) : Math.floor(d.asDays());
                        let hours = Math.floor(d.asHours()) - Math.floor(d.asDays()) * 24;
                        let hours1 = hours < 10 ? "0" + hours : hours;
                        let s = days + ":" + hours1 + moment.utc(ms).format(":mm");
                        let s1 = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
                        remainingCounter11 = s1;
                        remainingCounter = s.toString();
                    }
                }
                respData[i].time_start_now = remainingCounter;
                respData[i].time_start_now_new = remainingCounter11;
            }
            setLoder(false);
            setTodayclassList(respData);
            setMessageContent('');
        } else {
            setLoder(false);
            setTodayclassList([]);
        }
    }

    const checkDate = (currentdate) => {
        if (currentDateSelect == currentdate) {
            return false;
        } else {
            return true;
        }
    }

    const getDuration = (start, end) => {
        let start_date = moment.unix(start).format("MM-DD-YYYY");
        let start_time = moment.unix(start).format("HH:mm:ss");

        const str_date_split = start_date.split("-");
        const str_time_split = start_time.split(":");

        const start_day = Number(str_date_split[1]);
        const start_mon = Number(str_date_split[0]) - 1;
        const start_yea = Number(str_date_split[2]);

        const start_time_hour = Number(str_time_split[0]);
        const start_time_minu = Number(str_time_split[1]);
        const start_time_seco = Number(str_time_split[2]);

        let end_date = moment.unix(end).format("MM-DD-YYYY");
        let end_time = moment.unix(end).format("HH:mm:ss");

        const end_date_split = end_date.split("-");
        const end_time_split = end_time.split(":");

        const end_day = Number(end_date_split[1]);
        const end_mon = Number(end_date_split[0]) - 1;
        const end_yea = Number(end_date_split[2]);

        const end_time_hour = Number(end_time_split[0]);
        const end_time_minu = Number(end_time_split[1]);
        const end_time_seco = Number(end_time_split[2]);

        const st = new Date(
            start_yea,
            start_mon,
            start_day,
            start_time_hour,
            start_time_minu,
            start_time_seco,
            0
        );
        const en = new Date(
            end_yea,
            end_mon,
            end_day,
            end_time_hour,
            end_time_minu,
            end_time_seco,
            0
        );
        const diffMs = +en - +st;
        const diffMins = Math.floor(diffMs / 1000 / 60);
        return diffMins;
    }


    return (
        <>
            <section id="Workshops_Schedule">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="section-title text-center">
                                <h2 style={{ color: `${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts : ""}` }}>{maindata?.workshop_title ? maindata?.workshop_title : "Workshops"} Schedule</h2>
                                <small className='myline'></small>
                                <p>{webpreview && webpreview.wrk_description ? webpreview.wrk_description : "Invitamus me testatur sed"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="container-fluid">
                            <div className="bw-widget__sessions bw-widget__sessions--hide-empty-days">
                                <div className="bw-widget__day">
                                    <div className='row'>
                                        <div className="bw-widget__date" style={{ padding: '30px 15px', background: 'rgba(0, 0, 0, 0.03)' }}>
                                            <div className="col-xl-3 col-lg-6 col-md-4">
                                                <InputField
                                                    type="month"
                                                    lable=""
                                                    onChange={(e) => setMonthYearCombo(e.target.value)}
                                                    value={monthYearCombo}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3 myclassifram'>
                                        <ul className="list-groups" id='MonthlyListGroup'>
                                            {todayclassList?.length != 0 ?
                                                <div>
                                                    {todayclassList.map((classes, index) => (
                                                        <div key={index}>
                                                            <li className="list-group-item text-center mb-2">
                                                                {checkDate(classes.scheduled_date) &&
                                                                    <h4 className="text-left" style={{
                                                                        backgroundColor: "darkgray", color: "white", padding: "4px 10px",
                                                                        borderRadius: "10px", textAlign: "left"
                                                                    }}>{classes.scheduled_date}</h4>
                                                                }
                                                                <div className="card" style={{ textAlign: "left" }}>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            {classes.class_name}
                                                                        </h5>
                                                                        <div className="row">
                                                                            <div className="col-sm-4 mt-2">
                                                                                <strong>Time: </strong>
                                                                                <div className="">
                                                                                    {changeTimeStampToUTCDate(classes?.from_time)}
                                                                                    {' - '}
                                                                                    {changeTimeStampToUTCDate(classes?.to_time)}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-4 mt-2">
                                                                                <strong>Instructor: </strong>
                                                                                <div className="">{classes.instructor_name}</div>
                                                                            </div>
                                                                            {/* <div className="col-sm-4 mt-2" >
                                                                                <div>
                                                                                    <strong>Capacity: </strong>
                                                                                    <div className=""> {classes.capacity} </div>
                                                                                </div>
                                                                            </div> */}

                                                                            <div className="col-sm-4 mt-2">
                                                                                <strong>Duration [Minutes]: </strong>
                                                                                <div className="">
                                                                                    <div > {getDuration(classes.start, classes.end)}</div>

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-8 mt-2 card-title">
                                                                                <strong>Location: </strong>
                                                                                <div className="">
                                                                                    {classes?.location_url?.length > 0 ?
                                                                                        <div >
                                                                                            <Link to={classes.location_url} target="_blank"
                                                                                                style={{ color: "#007bff !important", textDecoration: "underline !important" }}>
                                                                                                {classes.location}</Link>
                                                                                        </div>
                                                                                        : <div > {classes.location} </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            {(param2 == '1' || param2 == '2') &&
                                                                                <div className="col-sm-4 mt-2">
                                                                                    {checkCurrentDateFilter(classes.scheduled_date, classes.from_time, classes.to_time) ?
                                                                                        <div>
                                                                                            <button type='button' className="mybtn mrp"
                                                                                                style={{ background: `${fullInfo && fullInfo.Borders_and_Buttons ? fullInfo.Borders_and_Buttons : "#0d92d1"}`, color: `${fullInfo && fullInfo.button_text_color ? fullInfo.button_text_color : "#FFFFFF"}` }}
                                                                                                onClick={() => setIsopen(true)}>Sign Up
                                                                                            </button>
                                                                                        </div>
                                                                                        : <div >
                                                                                            <button type='button' style={{ background: "#a9a9a9", color: "#fff" }} disabled>Sign Up</button>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                        </div>
                                                    ))}
                                                </div>
                                                : <div>
                                                    <li className="list-group-item text-center">{messageContent}</li>
                                                </div>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ))} */}

                    </div>
                </div>
            </section>
            {/* End Courses */}

            <Loginmodals opens={isopen} isClose={isClose} />

        </>
    )
}
