import React, { useEffect, useState } from 'react'
import { class_list_with_image } from '../../Services/Services'
import logo from "../../Images/logo_white_web.png"
import moment from 'moment';
import CommanImagePopup from '../../Mymodals/CommanImagePopup';

export const Course = ({ webpreview,maindata }) => {
    const [classdata, setClassdata] = useState([]);


    const getclass = async () => {
        const resp = await class_list_with_image({ "pageid": 1 });
        if (resp.data.status == 1) {
            const data = resp.data.data;
            setClassdata(data);
        } else {
            setClassdata([]);
        }
    }

    useEffect(() => {
        getclass();
    }, [])


    const [isopen, setIsopen] = useState(false);
    const isClose = () => { setIsopen(false); }
    const [img, setImg] = useState();
    const imgPopupHndler = (data) => {
        const final = {
            "image": data.image || "img/courses/1.jpg",
            "name": data.class_name,
            "description": data.description
        }
        setImg(final); setIsopen(true);
    }


    return (
        <>
            <section id="Classes">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="section-title text-center">
                                <h2 style={{color:`${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts :""}`}}>{maindata?.class_title ? maindata.class_title :"Classes"}</h2>
                                <small className='myline'></small>
                                <p>{webpreview && webpreview.cls_description ? webpreview.cls_description : "Invitamus me testatur sed"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{justifyContent:'center'}}>
                        {classdata && classdata.length > 0 && classdata.map((e, i) => (
                            <div className="col-xs-6 col-sm-6 col-md-4" key={i} style={{cursor:'pointer'}} onClick={() => imgPopupHndler(e)}>
                                <div className="course-list wow fadeInUp">
                                    <div className="course-thumb">
                                        <img src={e.image ? e.image : "img/courses/1.jpg"} alt="" />
                                    </div>
                                    <div className="course-content">
                                        <h3>
                                            {e.class_name}
                                        </h3>
                                        {/* <span className="time">
                                            <i className="fa fa-clock-o" /> {moment.unix(e.start_date_utc).format('DD MMM YYYY')}
                                        </span> */}
                                        <p>
                                            {e.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <CommanImagePopup isopen={isopen} isClose={isClose} data={img} type="To sign up to a class log into your Warrior account: "/>

        </>
    )
}
